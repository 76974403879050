import useOriginalTranslation from 'next-translate/useTranslation'
import React from 'react'

function useTranslation(namespace: string, identifier: string, note?: string) {
	const tr = useOriginalTranslation()

	const key = `${namespace}:${identifier}`
	const value = tr.t(key)
	const isMissing = value === key

	React.useEffect(() => {
		if (isMissing) {
			setTimeout(() => {
				const body = JSON.stringify({
					identifier,
					namespace: namespace ?? 'common',
					note: note ?? '',
				})
				fetch('/api/register-tr', {
					method: 'POST',
					headers: {
						'content-type': 'application/json;chartset=utf-8',
					},
					body,
				})
			}, 10000)
		}
	}, [isMissing, note, namespace, identifier])

	return value
}

export { useTranslation }

export const Tr = React.memo(function Tr({
	t,
	ns,
	format,
	note,
}: {
	t: string
	ns?: string
	note?: string
	format?: (value: string) => string
}) {
	const value = useTranslation(ns ?? 'common', t, note)

	if (value === String(`${ns}:${t}`)) {
		return <span>{t}</span>
	}

	return <>{format ? format(value) : value}</>
})
