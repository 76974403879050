import React from 'react'
import { ClotinoIcon } from '../../generated/clotino-icons'
import { useTranslation } from '../components/Tr'
import { findMotive } from '../loaders/findMotive'
import { IconSetResult } from '../loaders/loadShop'

const VERSION = 'v1'

const ICON_LOCALSTORAGE_KEY = `clotino:${VERSION}:icon`
const LABEL_LOCALSTORAGE_KEY = `clotino:${VERSION}:label`

export type ClotinoMotive = {
	icon?: null | ClotinoIcon
	label?: null | string
	visibleIcon: ClotinoIcon
	visibleLabel: string
	placeholderLabel: string
	motive?: ReturnType<typeof findMotive>
}

export type ClotinoMotiveDefaults = {
	label?: string
	icon?: ClotinoIcon
}

export type ClotinoMotiveContextType = ClotinoMotive & {
	iconSet: IconSetResult
	setIcon: (icon: ClotinoMotive['icon']) => void
	setLabel: (icon: ClotinoMotive['label']) => void
	reset: () => void
}

export const ClotinoMotiveContext = React.createContext<ClotinoMotiveContextType>({
	icon: null,
	label: null,
	placeholderLabel: '',
	visibleLabel: '',
	visibleIcon: 'liska',
	iconSet: [],
	setIcon: () => {
		throw new Error('Default setIcon')
	},
	setLabel: () => {
		throw new Error('Default setLabel')
	},
	reset: () => {
		throw new Error('Default reset')
	},
})

export function useClotinoMotive() {
	return React.useContext(ClotinoMotiveContext)
}

export function useClotinoMotiveProviderValue(
	defaults?: ClotinoMotiveDefaults,
	iconSet?: IconSetResult
): ClotinoMotiveContextType {
	console.log('motive', { defaults })
	const defaultIcon = defaults?.icon ?? null

	const defaultLabel = defaults?.label ?? null

	const [init, setInit] = React.useState(true)

	const [icon, setIcon] = React.useState<ClotinoMotive['icon']>(defaultIcon)
	const [label, setLabel] = React.useState<ClotinoMotive['label']>(defaultLabel)

	const reset = React.useCallback(() => {
		setIcon(null)
		setLabel(null)
	}, [])

	const placeholderLabel = useTranslation('common', 'stamp_placeholder')

	const visibleIcon = icon || 'liska'
	const visibleLabel = (label || placeholderLabel).trim()

	React.useEffect(() => {
		if (process.browser) {
			if (init) {
				setInit(false)
				if (!icon) {
					const storedIcon = window.localStorage.getItem(
						ICON_LOCALSTORAGE_KEY
					) as ClotinoMotive['icon']
					if (storedIcon) {
						setIcon(storedIcon)
					}
				}
				if (!label) {
					const storedLabel = window.localStorage.getItem(
						LABEL_LOCALSTORAGE_KEY
					) as ClotinoMotive['icon']
					if (storedLabel) {
						setLabel(storedLabel)
					}
				}
			} else {
				if (!icon && label) {
					setIcon(visibleIcon)
				}
			}
		}
	}, [icon, init, label, visibleIcon])

	React.useEffect(() => {
		if (process.browser) {
			if (icon) {
				window.localStorage.setItem(ICON_LOCALSTORAGE_KEY, String(icon))
			} else {
				window.localStorage.removeItem(ICON_LOCALSTORAGE_KEY)
			}
			if (label) {
				window.localStorage.setItem(LABEL_LOCALSTORAGE_KEY, String(label))
			} else {
				window.localStorage.removeItem(LABEL_LOCALSTORAGE_KEY)
			}
		}
	}, [icon, label])

	return {
		icon: icon ?? null,
		label: label ?? null,
		setIcon,
		setLabel,
		reset,
		iconSet: iconSet ?? [],
		motive: findMotive(iconSet ?? [], visibleIcon as string, visibleLabel as string),
		visibleIcon,
		visibleLabel,
		placeholderLabel,
	}
}
