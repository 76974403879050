import { IconSetResult } from './loadShop'

export function findMotive(iconSet: IconSetResult, icon?: string, text?: string) {
	for (const i of iconSet) {
		if (i.id === icon) {
			return { icon: i, text }
		}
		if (i.key === icon) {
			return { icon: i, text }
		}
		if (i.unique === icon) {
			return { icon: i, text }
		}
	}
}
