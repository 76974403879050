import React from 'react'
import { useDebounce } from 'use-debounce'
import { ClotinoIcon } from '../../generated/clotino-icons'
import { useClotinoMotive } from '../hooks/useClotinoMotive'
import { findMotive } from '../loaders/findMotive'
import s from './StampImg.module.sass'

export function StampImg(props: { icon?: ClotinoIcon | null; label: string }) {
	const [debounceIcon] = useDebounce(props.icon, 100)
	const [debounceLabel] = useDebounce(props.label, 500)
	const { iconSet } = useClotinoMotive()

	const found = findMotive(iconSet ?? [], debounceIcon ?? undefined)

	let src = `/api/stamp?icon=${encodeURIComponent(
		found?.icon.key ?? debounceIcon ?? ''
	)}&label=${encodeURIComponent(debounceLabel)}`

	if (debounceLabel === 'konina') {
		src = '/clotino/assets/konina.svg'
	}

	if (debounceLabel === 'goodlok') {
		src = '/clotino/assets/goodlok.svg'
	}

	if (debounceLabel === 'goodlok2') {
		src = '/clotino/assets/goodlok2.svg'
	}

	return (
		<span className={s.StampImg}>
			<span className={s.Ratio}></span>
			<img className={s.Img} src={src} alt={`motive ${debounceLabel}`} />
		</span>
	)
}

export const StampIcon = React.memo(function StampIcon(props: { icon?: ClotinoIcon | null }) {
	const [debounceIcon] = useDebounce(props.icon, 100)

	const { iconSet } = useClotinoMotive()

	const found = findMotive(iconSet ?? [], debounceIcon ?? undefined)

	const src = found?.icon.image.url ?? `https://mgwdata.net/clotino/beta/${debounceIcon}.svg`

	return (
		<span className={s.StampImg}>
			<span className={s.SquareRatio}></span>
			{debounceIcon && debounceIcon !== '-' && (
				<img loading="lazy" className={s.Img} src={src} alt={`motive ${debounceIcon}`} />
			)}
		</span>
	)
})
